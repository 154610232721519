import React from 'react';
import "./css/modal.css";
import ReactPixel from 'react-facebook-pixel';

export default function Modal({modal, content, toggleModal, title="BŁĄD"}) {
  if(modal) {
    document.body.classList.add('active-modal');
    ReactPixel.init('2143226812680472');
    ReactPixel.pageView();
  } else {
    document.body.classList.remove('active-modal')
  }

  return (
    <>
      {modal && (
        <div className="pop-up">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="pop-up-content">
            <h3>{title}</h3>
            <p>{content}</p>
            <button className="close-pop-up" onClick={toggleModal}>
              Zamknij
            </button>
          </div>
        </div>
      )}
    </>
  );
}