import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import SectionHeader from './sectionHeader.js';
import ConferenceTile from './conferenceTile.js';
import CourseTile from './courseTile.js';


export default function Section({backgroundColor, color, secondaryColor, thirdColor, name, courses}) {
    const updateTileHeight = () => {
        const rows = document.querySelectorAll('.courses-row');
        let maxHeight = 0;
        rows.forEach(row => {
          row.querySelectorAll('.conference-tile-name').forEach(tile => {
            tile.style.height = 'auto'; // Reset height to auto before recalculating
            const height = tile.offsetHeight;
            if (height > maxHeight) {
              maxHeight = height;
            }
          });
        });
        rows.forEach(row => {
          row.querySelectorAll('.conference-tile-name').forEach(tile => {
            tile.style.height = `${maxHeight}px`; // Set the height to the max height
          });
        });
      };

    useEffect(() => {
        window.addEventListener('resize', updateTileHeight);

        return () => {
            // Clean up the event listener on unmount
            window.removeEventListener('resize', updateTileHeight);
        };
    }, []);

    useEffect(() => {
        updateTileHeight(); // Initial update      
    }, [courses]);
      
    if (courses.length === 0) return null;

    let conferences = courses.filter(c => c.Type_event.Value === 'Zjazd');
    let ordinaryCourses = courses.filter(c => c.Type_event.Value !== 'Zjazd');

    let i = 0, j = 0;
    let coursesGrid = [];
    for (i = 0; i * 3 <= ordinaryCourses.length; i++) {
        let coursesRow = [];
        for (j = 0; j < 3 && i * 3 + j < ordinaryCourses.length; j++) {
            coursesRow.push(ordinaryCourses[i * 3 + j]);
        }
        coursesGrid.push(coursesRow);
    }

    let conferencesGrid = [];
    for (i = 0; i * 3 <= conferences.length; i++) {
        let coursesRow = [];
        for (j = 0; j < 3 && i * 3 + j < conferences.length; j++) {
            coursesRow.push(conferences[i * 3 + j]);
        }
        conferencesGrid.push(coursesRow);
    }

    
    
    return <div className="section-wrapper" style={{backgroundColor: backgroundColor, color: color}}>
        <div className="section">
            <SectionHeader color={color} text={name} style={{backgroundColor: backgroundColor}}/>
            <div className="courses-section" style={{backgroundColor: backgroundColor}}>
                {conferencesGrid.map(function(r, i) {
                    return <Row className="courses-row" key={name + '_conference_' + i}>
                    {r.length < 3 ? <Col xl={1} lg={1} md={0} sm={0} xs={0}></Col> : null}
                    {r.map((c, j) =>
                        <ConferenceTile key={name + '_conference_' + j} color={color} secondaryColor={secondaryColor} thirdColor={thirdColor} style={{backgroundColor: backgroundColor}} course={c} />         
                    )}
                    {r.length < 3 ? <Col xl={1} lg={1} md={0} sm={0} xs={0}></Col> : null}
                </Row>;
                })}
            </div>
            <div className="courses-section" style={{backgroundColor: backgroundColor}}>
                {coursesGrid.map(function(r, i) {
                    return <Row className="courses-row" key={name + '_course_' + i}>
                        {r.length < 3 ? <Col xl={1} lg={1} md={0} sm={0} xs={0}></Col> : null}
                        {r.map((c, j) =>
                            <CourseTile key={c.ID} color={color} secondaryColor={secondaryColor} thirdColor={thirdColor} course={c} />
                        )}
                        {r.length < 3 ? <Col xl={1} lg={1} md={0} sm={0} xs={0}></Col> : null}
                    </Row>;
                })}
            </div>
        </div>
    </div>;
}