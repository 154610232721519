import { Field, ErrorMessage } from "formik";
import { Row, Col } from 'react-bootstrap';

export default function SelectComponent({labelName, name, selectData}) {
    if (selectData.length > 0) {
        let data = selectData[0];
    
        return <>
            <div className="label">{labelName + (data.Required ? '*' : '')}</div>
            <Row>
                <Col xl={5} lg={7} md={8} sm={11} xs={11}>
                    <Field as="select" name={name} >
                        <option value=""> -- wybierz -- </option>
                        {data.Options.map((o, i) => <option key={`${name}_option_${i}`} value={o.Title}>{o.Title}</option>)}
                    </Field>
                    <ErrorMessage name={name} component="div" className="error" />
                </Col>
            </Row>
        </>;
    }

    return null;
}