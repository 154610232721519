import React from 'react';
import { Col } from 'react-bootstrap';
import convertToDateRange from './utils/convertToDateRange.js';
import { useNavigate } from 'react-router-dom';

export default function ConferenceTile({color, secondaryColor, thirdColor, course}) {
    let startDate = new Date(Date.parse(course.Start_date));
    let endDate = new Date(Date.parse(course.End_date));
    const navigate = useNavigate();

    const navigateToForm = () => {
        navigate(`/register/${course.ID.replace('#', '')}`);
    }
    
    return <Col xl={3} lg={3} md={5} sm={8} xs={10}>
        <div className="conference-tile" style={{backgroundColor: secondaryColor}}>
            <div className="tile-border-data">{convertToDateRange(startDate, endDate)}</div>
            <div className="conference-tile-name" style={{backgroundColor: color}}>{course.Short_name}</div>
            <hr style={{border: "1px solid" + thirdColor}} />
            <div className="tile-info" style={{backgroundColor: color, color: thirdColor}}>
                {course.Discount_price === null ?
                parseFloat(course.Price).toFixed(2).replace('.', ',') + ' zł' : 
                <div className="tile-price-info">
                    <span className="strikethrough">{parseFloat(course.Price).toFixed(2).replace('.', ',')} zł</span>
                    <span>{parseFloat(course.Discount_price).toFixed(2).replace('.', ',')} zł</span>
                </div>}
            </div>
            <div className="tile-border-data" onClick={navigateToForm}>ZAPISZ SIĘ NA WYDARZENIE</div>
        </div>
    </Col>;
}