import { ErrorMessage } from "formik";
import Select from 'react-select';
import { Row, Col } from 'react-bootstrap';

export default function MultiSelectComponent({labelName, name, props, selectData}) {
    if (selectData.length > 0) {
        let data = selectData[0];
        
        return <>
            <div className="label">{labelName + (data.Required ? '*' : '')}</div>
            <Row>
                <Col xl={5} lg={7} md={8} sm={11} xs={11}>
                    <Select name={name} isMulti
                    options={data.Options.map((o, i) => {return {label: o.Title, value: o.Title};})}
                    onChange={(value) => props.setFieldValue("otherQualifications", value)}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderWidth: '2px',
                            borderColor: props.errors.otherQualifications ? "red" : '#115e92 !important',
                            fontSize: '28px',
                            cursor: 'pointer',
                            padding: '0.5vh 0 0.5vh 2vw',
                            borderRadius: 0,
                            margin: 'auto'
                        }),
                        placeholder: (baseStyles, state) => ({
                            ...baseStyles,
                            color: 'transparent'
                        }),
                        indicatorSeparator: (baseStyles, state) => ({
                            ...baseStyles,
                            display: 'none'
                        }),
                        dropdownIndicator: (baseStyles, state) => ({
                            ...baseStyles,
                            height: '40px',
                            width: '40px',
                            background: 'white',
                            backgroundImage: 'url("data:image/svg+xml;utf8,<svg fill=\'white\' height=\'32\' viewBox=\'0 0 32 32\' width=\'32\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M0 0h32v32H0z\' fill=\'%23115e92\'/><path d=\'M12 13l5 5 5-5z\'/></svg>")',
                            backgroundRepeat: 'no-repeat',
                            backgroundPositionX: '-2px',
                            backgroundPositionY: '5px',
                            backgroundColor: 'transparent',
                            color: 'transparent !important'
                        }),
                        indicatorContainer: (baseStyles, state) => ({
                            ...baseStyles
                        }),
                        menu: (baseStyles, state) => ({
                            ...baseStyles,
                            width: '28vw',
                            borderRadius: 0,
                            marginTop: 0,
                            left: '50%',
                            transform: 'translateX(-50%)',
                        })
                        }}>
                    </Select>
                    <ErrorMessage name={name} component="div" className="error" />
                </Col>
            </Row>
        </>;
    }

    return null;
}