import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useState } from 'react';
import { Autocomplete, TextField} from '@mui/material';
import * as Yup from "yup";
import { Container, Row, Col } from 'react-bootstrap';

import InputComponent from './inputComponent';
import SelectComponent from './selectComponent';
import MultiSelectComponent from './multipleSelect';
import AgreementRow from './agreementRow';
import Modal from './modal.js';

export default function RegisterForm({id, initialValues, fields, agreementsSchema}) {

    // console.log(fields);
    const [postCities, setPostCities] = useState([]);

    const [modal, setModal] = useState(false);
    const [modalText, setModalText] = useState('');
    const [modalTitle, setModalTitle] = useState('');

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

    const toggleModal = () => {
      setModal(!modal);
    };

    const nameRegex = /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøśùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØŚÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð -])+$/;
    const textWithNumbersRegex = /^([0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøśùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØŚÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð -])+$/;
    const requiredMessage = 'Pole wymagane';
    const isFieldRequired = (fieldName) => fields.Form_questions.filter(q => q.Content === fieldName).length > 0 && fields.Form_questions.filter(q => q.Content === fieldName)[0].Required;
    let cornerDateBirth = new Date();
    cornerDateBirth.setFullYear(cornerDateBirth.getFullYear() - 18);

    const requiredString = () => Yup.string().required(requiredMessage);
    const commonValidation = (fieldName) => Yup.string().when('havingParticipantId', {
        is: (havingParticipantId) => ['false'].includes(havingParticipantId) && isFieldRequired(fieldName),
        then: requiredString});
    const commonValidationWithAdditionalRequirements = (fieldName, regex, regexMessage = 'Niedozwolone znaki', min = 0, minMessage = 'Za mało znaków') => {
        if (min !== 0)
            return Yup.string().when('havingParticipantId', {
                is: (havingParticipantId) => ['false'].includes(havingParticipantId) && isFieldRequired(fieldName), 
                then: () => Yup.string().required(requiredMessage).min(min, minMessage).matches(regex, regexMessage),
                otherwise: () => Yup.string().min(min, minMessage).matches(regex, regexMessage)});

        return Yup.string().when('havingParticipantId', {
            is: (havingParticipantId) => ['false'].includes(havingParticipantId) && isFieldRequired(fieldName), 
            then: () => Yup.string().required(requiredMessage).matches(regex, regexMessage),
            otherwise: () => Yup.string().matches(regex, regexMessage)});
    }

    const schema = Yup.object().shape({
        havingParticipantId: requiredString(),
        participantId: Yup.string().when('havingParticipantId', {
            is: (havingParticipantId) => ['true'].includes(havingParticipantId), 
            then: () => Yup.string().required(requiredMessage).matches(/^ID[a-zA-Z0-9]{8}$/, 'Niepoprawny format ID')}),
        firstName: commonValidationWithAdditionalRequirements('Imię', nameRegex, 'Niedozwolone znaki', 3, 'Wpisz co najmniej 3 znaki'),
        surname: commonValidationWithAdditionalRequirements('Nazwisko', nameRegex, 'Niedozwolone znaki', 2, 'Wpisz co najmniej 2 znaki'),
        mail: Yup.string().when('havingParticipantId', {
            is: (havingParticipantId) => ['false'].includes(havingParticipantId) && isFieldRequired('E-mail'),
            then: () => Yup.string().required(requiredMessage).email('Niepoprawny format e-mail').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Niepoprawny format e-mail'),
            otherwise: () => Yup.string().email('Niepoprawny format e-mail').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Niepoprawny format e-mail')}),
        phoneNumber: commonValidationWithAdditionalRequirements('Numer telefonu do powiadomień SMS', /^[+]*[0-9]{9,12}$/, 'Niepoprawny numer telefonu'),
        birthDate: Yup.string().when('havingParticipantId', {
            is: (havingParticipantId) => ['false'].includes(havingParticipantId) && isFieldRequired('Data urodzenia'),
            then: () => Yup.string().matches(/^\d{2}.\d{2}.\d{4}$/, 'Niepoprawny format daty').required(requiredMessage),//.max(cornerDateBirth.toLocaleDateString(), "Wymagany wiek to co najmniej 18 lat"), 
            otherwise: () => Yup.string().matches(/^\d{2}.\d{2}.\d{4}$/, 'Niepoprawny format daty')}),//.max(cornerDateBirth.toLocaleDateString(), "Wymagany wiek to co najmniej 18 lat")}),
        birthPlace: commonValidationWithAdditionalRequirements('Miejsce urodzenia', nameRegex, 'Niedozwolone znaki', 2, 'Wpisz co najmniej 2 znaki'),
        occupation: commonValidation('Zawód'),
        rightToProfessionNo: commonValidationWithAdditionalRequirements('Numer prawa wykonywania zawodu', /^[0-9]{7}[a-zA-Z]{0,1}$/, 'Niepoprawny format'),
        academicTitle: commonValidation('Tytuł naukowy'),
        workPlace: commonValidationWithAdditionalRequirements('Miejsce pracy (nazwa i adres)', textWithNumbersRegex, 'Niedozwolone znaki', 2, 'Wpisz co najmniej 2 znaki'),
        isCDL: commonValidation('Jestem CDL'),
        cdlCertificateNo: commonValidationWithAdditionalRequirements('Nr certyfikatu CDL (opcjonalnie)', /^[0-9a-zA-Z/]*$/, 'Niedozwolone znaki'),
        otherQualifications: Yup.array().when('havingParticipantId', {
            is: (havingParticipantId) => ['false'].includes(havingParticipantId) && isFieldRequired('Inne kwalifikacje laktacyjne'),
            then: () => Yup.array().min(1, requiredMessage).of(
                Yup.object().shape({label: Yup.string().required(), value: Yup.string().required()})
              )}),
        city: commonValidationWithAdditionalRequirements('Miejscowość', nameRegex, 'Niedozwolone znaki', 2, 'Wpisz co najmniej 2 znaki'),
        placeType: commonValidation('Ulica/aleja/plac'),
        street: commonValidationWithAdditionalRequirements('Ulica/aleja/plac', nameRegex, 'Niedozwolone znaki', 2, 'Wpisz co najmniej 2 znaki'),
        houseNo: commonValidationWithAdditionalRequirements('Nr budynku', /^[0-9a-zA-Z]*$/),
        localNo: commonValidationWithAdditionalRequirements('Nr lokalu', /^[0-9a-zA-Z]*$/),
        postalCode: commonValidationWithAdditionalRequirements('Kod pocztowy', /^[0-9]{2}-[0-9]{3}$/, 'Niepoprawny kod pocztowy - format 00-000'),
        postCity: commonValidationWithAdditionalRequirements('Miejscowość (poczta)', nameRegex, 'Niedozwolone znaki', 2, 'Wpisz co najmniej 2 znaki'),
        ...agreementsSchema
    });

    const remindId = () => {
        window.open('https://forms.office.com/pages/responsepage.aspx?id=WsVf-tDR30aTt41vK0vU71-iA3_ubnxOsVoprxFCDAtUNTNLNUVERUo4ME9KNUpGTVQ3QjZDVUhJUC4u', '_blank', 'noreferrer');
    }

    const handlePostalCodeKeyUp = (e) => {
        if (new RegExp('^[0-9]{2}-[0-9]{3}$').test(e.target.value)) {
            //https://rapidapi.com/intami-intami-default/api/polish-zip-codes1/pricing
            fetch(`https://kodpocztowy.intami.pl/api/${e.target.value}`, {
                method: 'GET',
                headers : {'Accept' : 'application/json' }})
            .then(r=>r.json())
            .then((response) => {
                setPostCities(Array.from(new Set(response.map(x => x.miejscowosc))));
            })
            .catch( error => {
                console.log('ERROR: ' + error);
            });
        }
    }
    
    const submitForm = (values) => {
        setSubmitButtonDisabled(true);
        let saveParams = JSON.parse(JSON.stringify(values)); //deep copy
        saveParams.eventId = '#' + id;
        saveParams.isCDL = (values.isCDL === 'true');
        saveParams.havingParticipantId = (values.havingParticipantId === 'true');
        saveParams.otherQualifications = values.otherQualifications.map(q => {return {content: q.value};});
        saveParams.name = saveParams.firstName;
        delete saveParams.firstName;

        const agreementKeys = Object.keys(values).filter(k => k.includes('agreement'));     
        saveParams.agreements = agreementKeys.map((k, i) => {
            return {content: fields.Declarations[i].Content, checked: values[k]};
        });
        agreementKeys.forEach((k, i) => delete saveParams[k]);
        
        fetch('https://prod-22.westeurope.logic.azure.com/workflows/40a4a54a113a43519601da0e8263d211/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ll2O9v8LBN3U_Tt7eSFhYabIHdW6MIfwNs0Z55jLhuQ', {
        method: 'POST', 
        headers : {
            'Content-Type' : 'application/json'
        },
        body: JSON.stringify(saveParams)})
        .then(response => {
            if (response.status !== 200) {
                setModalText('Coś poszło nie tak. Spróbuj ponownie później.');
                setModalTitle('BŁĄD');
                toggleModal();
            } else {
                return response.json();
            }
            setSubmitButtonDisabled(false);
        })
        .then(body => {
            setModalTitle(body != null && body !== undefined && body.Status === 'true' ? 'SUKCES' : 'BŁĄD');
            setModalText(body != null && body !== undefined ? body.Message : '');
            toggleModal();
        })
        .catch( error => {
            console.log('ERROR: ' + error);
            setModalText('Coś poszło nie tak. Spróbuj ponownie później.');
            setModalTitle('BŁĄD');
            toggleModal();
        });
    }

    const submitBtnClick = () => {
        setTimeout(() => {
            const firstErrorField = document.querySelector('input:has(+ div.error), select:has(+ div.error), .error');
            if (firstErrorField) {
                firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 400);
    }

    return (<Container fluid>
    <Modal modal={modal} content={modalText} title={modalTitle} toggleModal={toggleModal}/>
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={submitForm} validateOnBlur={false} >
    {(props) =>
    (
        <Form autoComplete="off">
            <div className="page">
                <div className="label">Mam identyfikator zapisów na wydarzenia CNoL (rozpoczynający się od ID)*</div>
                <Row>
                    <Col xl={5} lg={7} md={8} sm={11} xs={11}>
                        <Field as="select" name="havingParticipantId" >
                            <option value=""> -- wybierz -- </option>
                            <option value="true">TAK</option>
                            <option value="false">NIE</option>
                        </Field>
                        <ErrorMessage name="havingParticipantId" component="div" className="error" />
                    </Col>
                </Row>
                {props.values.havingParticipantId === "true" ?
                    <>
                        <div>
                            <button type='button' onClick={remindId}>Przypomnij ID</button>
                        </div>
                        <div className="label">Wpisz identyfikator uczestnika, rozpoczynający się od "ID" *</div>
                        <div className="">np.ID12a34b56</div>
                        <Row>
                            <Col xl={5} lg={7} md={8} sm={11} xs={11}>
                                <Field type="text" name="participantId" className={props.errors.participantId ? props.errors.participantId : null} />
                                <ErrorMessage name="participantId" component="div" className="error" />
                            </Col>
                        </Row>
                    </>
                    : (props.values.havingParticipantId === "false" ?                
                    <>
                    <InputComponent labelName="Imię" type="text" name="firstName" inputRequirements={fields.Form_questions.filter(q => q.Content === 'Imię')} />
                    <InputComponent labelName="Nazwisko" type="text" name="surname" inputRequirements={fields.Form_questions.filter(q => q.Content === 'Nazwisko')} />
                    <InputComponent labelName="E-mail" type="email" name="mail" inputRequirements={fields.Form_questions.filter(q => q.Content === 'E-mail')} />
                    <InputComponent labelName="Numer telefonu do powiadomień SMS" type="tel" name="phoneNumber" inputRequirements={fields.Form_questions.filter(q => q.Content === 'Numer telefonu do powiadomień SMS')} />
                    
                    {fields.Form_questions.filter(q => q.Content === 'Data urodzenia').length > 0
                    ? <>
                        <div className="label">{"Data urodzenia" + (fields.Form_questions.filter(q => q.Content === 'Data urodzenia')[0].Required ? '*' : '')}</div>
                        <Row>
                            <Col xl={5} lg={7} md={8} sm={11} xs={11}>
                                <Field type="text" name="birthDate" placeholder="dd.mm.rrrr" autoComplete="new-password" onClick={(e) => e.preventDefault()} />
                                <ErrorMessage name="birthDate" component="div" className="error" />
                            </Col>
                        </Row>
                    </> : null}
                    
                    <InputComponent labelName="Miejsce urodzenia" type="text" name="birthPlace" inputRequirements={fields.Form_questions.filter(q => q.Content === 'Miejsce urodzenia')} />

                    <SelectComponent labelName="Zawód" name="occupation" selectData={fields.Form_questions.filter(q => q.Content === 'Zawód')} />
                    <InputComponent labelName="Numer prawa wykonywania zawodu" type="text" name="rightToProfessionNo" inputRequirements={fields.Form_questions.filter(q => q.Content === 'Numer prawa wykonywania zawodu')} />
                    <SelectComponent labelName="Tytuł zawodowy/naukowy" name="academicTitle" selectData={fields.Form_questions.filter(q => q.Content === 'Tytuł naukowy')} />
                    <InputComponent labelName="Miejsce pracy (nazwa i adres)" type="text" name="workPlace" inputRequirements={fields.Form_questions.filter(q => q.Content === 'Miejsce pracy (nazwa i adres)')} />

                    {fields.Form_questions.filter(q => q.Content === 'Jestem CDL').length > 0
                    ? <>
                        <div className="label">Jestem CDL{fields.Form_questions.filter(q => q.Content === 'Jestem CDL')[0].Required && '*'}</div>
                        <Row className="radio-section">
                            <Col xl={5} lg={7} md={8} sm={11} xs={11}>
                                <Row>
                                    <Col xl={6} lg={6} md={6} sm={6} xs={10}>
                                        <label>Tak
                                            <Field type="radio" name="isCDL" value="true" className={props.errors.isCDL && "error"} />
                                            <span></span>
                                        </label>
                                    </Col>
                                    <Col xl={6} lg={6} md={6} sm={6} xs={10}>
                                        <label>Nie
                                            <Field type="radio" name="isCDL" value="false" className={props.errors.isCDL && "error"} />
                                            <span></span>
                                        </label>
                                    </Col>
                                </Row>
                                <ErrorMessage name="isCDL" component="div" className="error" />
                            </Col>
                        </Row>
                        {props.values.isCDL === 'true' &&
                        <InputComponent labelName="Numer certyfikatu CDL (opcjonalnie)" type="text" name="cdlCertificateNo" inputRequirements={fields.Form_questions.filter(q => q.Content === 'Numer certyfikatu CDL (opcjonalnie)')} />
                        }
                    </> : null}

                    <MultiSelectComponent labelName="Inne kwalifikacje laktacyjne" name="otherQualifications" props={props} selectData={fields.Form_questions.filter(q => q.Content === 'Inne kwalifikacje laktacyjne')} />
                    
                    {['Miejscowość', 'Ulica/aleja/plac', 'Nr budynku', 'Nr lokalu', 'Kod pocztowy', 'Miejscowość (poczta)'].some(d => fields.Form_questions.map(q => q.Content).includes(d))
                        ? <div className="label-header">Adres do korespondencji</div>
                        : null
                    }

                    <InputComponent labelName="Miejscowość" type="text" name="city" inputRequirements={fields.Form_questions.filter(q => q.Content === 'Miejscowość')} />
                    {fields.Form_questions.filter(q => q.Content === 'Ulica/aleja/plac').length > 0
                    ? <>
                        <div className="label">{'Ulica/aleja/plac' + (fields.Form_questions.filter(q => q.Content === 'Ulica/aleja/plac')[0].Required ? '*' : '')}</div>
                        <Row>
                            <Col xl={5} lg={7} md={8} sm={11} xs={11}>
                                <Row>
                                    <Col xl={4} lg={4} md={4} sm={4} xs={4} style={{padding: 0}}>
                                        <Field as="select" name="placeType" className="placeType">
                                            <option value="Ulica">Ulica</option>
                                            <option value="Aleja">Aleja</option>
                                            <option value="Plac">Plac</option>
                                            <option value="Osiedle">Osiedle</option>
                                        </Field>
                                    </Col>
                                    <Col xl={8} lg={8} md={8} sm={8} xs={8} style={{padding: 0}}>
                                        <Field type="text" name="street" />
                                        <ErrorMessage name="street" component="div" className="error" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </> : null}
                    
                    <InputComponent labelName="Nr budynku" type="text" name="houseNo" inputRequirements={fields.Form_questions.filter(q => q.Content === 'Nr budynku')} />
                    <InputComponent labelName="Nr lokalu" type="text" name="localNo" inputRequirements={fields.Form_questions.filter(q => q.Content === 'Nr lokalu')} />
                    <InputComponent labelName="Kod pocztowy" type="text" name="postalCode" onKeyUpHandler={handlePostalCodeKeyUp} inputRequirements={fields.Form_questions.filter(q => q.Content === 'Kod pocztowy')} />
                                        
                    {fields.Form_questions.filter(q => q.Content === 'Miejscowość (poczta)').length > 0 ?
                    <>
                        <div className="label">{'Miejscowość (poczta)' + (fields.Form_questions.filter(q => q.Content === 'Miejscowość (poczta)').Required ? '*' : '')}</div>
                        <Row>
                            <Col xl={5} lg={7} md={8} sm={11} xs={11}>
                                <Autocomplete name="postCity" options={postCities} freeSolo={true}
                                    onKeyUp={(e) => props.setFieldValue("postCity", e.target.value)}
                                    onChange={(e, value) => props.setFieldValue("postCity", value)}
                                    renderInput={(params) => <TextField {...params} /> }/>
                                <ErrorMessage name="postCity" component="div" className="error" />
                            </Col>
                        </Row>
                    </>
                    : null}
                    
                    </>
                    : null)}
                    <div className="bottom-label">* Pola wymagane</div>

            </div>
            <div className="page bottom-section">
                <Row>
                    <Col xl={8} lg={8} md={8} sm={10} xs={10} className="agreements-section-header">Oświadczenia</Col>
                </Row>
                <Row>
                    <Col xl={8} lg={8} md={8} sm={10} xs={10} className="agreements-section">
                        {fields.hasOwnProperty('Declarations') && fields.Declarations.map((d, i) =>
                            <AgreementRow key={`declaration_${d.ID}`} name={`agreement_${d.ID}`} data={d} errors={props.errors} isSubmitted={props.submitCount > 0} />)}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <button type="submit" onClick={submitBtnClick} disabled={submitButtonDisabled}>ZAPISZ</button>
                    </Col>
                </Row>
            </div>
        </Form>)
    }
    </Formik>
    </Container>);
}