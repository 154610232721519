export default function convertToDateRange(startDate, endDate) {
    let startMonth = startDate.getMonth() + 1;
    startMonth = (startMonth < 10 ? '0' : '') + startMonth;
    let endMonth = endDate.getMonth() + 1;
    endMonth = (endMonth < 10 ? '0' : '') + endMonth;
    if (startDate.getFullYear() === endDate.getFullYear()) {
        if (startMonth === endMonth) {
            if (startDate.getDate() === endDate.getDate()) {
                return `${startDate.getDate()}.${startMonth}.${startDate.getFullYear()}`;
            } else {
                return `${startDate.getDate()}-${endDate.getDate()}.${endMonth}.${endDate.getFullYear()}`;
            }
        } else {
            return `${startDate.getDate()}.${startMonth}-${endDate.getDate()}.${endMonth}.${endDate.getFullYear()}`;
        }
    }

    return `${startDate.getDate()}.${startMonth}.${startDate.getFullYear()}-${endDate.getDate()}.${endMonth}.${endDate.getFullYear()}`;
}