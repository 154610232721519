import './css/App.css';
import CoursesPage from './coursesPage';
import RegisterFormPage from './registerFormPage';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

export default function App() {
  return (
      <>
          <Router>
              <Routes>
                  <Route
                      exact
                      path="/"
                      element={<CoursesPage />}
                  />
                  <Route
                      exact
                      path="/register/:id"
                      element={<RegisterFormPage />}
                  />
              </Routes>
          </Router>
      </>
  );
  
}


//#115e92 - niebieski cnolowy