import { Field, ErrorMessage } from "formik";
import { Row, Col } from 'react-bootstrap';

export default function InputComponent({labelName, type, name, inputRequirements, onKeyUpHandler=null}) {
    if (inputRequirements.length > 0) {
        const  data = inputRequirements[0];
        
        return <>
            <div className="label">{labelName + (data.Required ? '*' : '')}</div>
            <Row>
                <Col xl={5} lg={7} md={8} sm={11} xs={11}>
                    {onKeyUpHandler != null
                        ? <Field type={type} name={name} onKeyUp={onKeyUpHandler} autoComplete="new-password"/>
                        : <Field type={type} name={name} autoComplete="new-password"/>}
                    <ErrorMessage name={name} component="div" className="error" />
                </Col>
            </Row>
        </>;
    }

    return null;
}