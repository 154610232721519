import React, { useState, useEffect } from 'react';
import {useParams} from 'react-router-dom';
import * as Yup from "yup";

import './css/registerForm.css';
import cnolLogo from './cnol-logo.jpg';
import ftkLogo from './ftk-logo.jpg';
import RegisterForm from './registerForm';
import Modal from './modal.js';

export default function RegisterFormPage() {
    const {id} = useParams();

    const [eventName, setEventName] = useState('');
    const [modal, setModal] = useState(false);
    const [modalText, setModalText] = useState('');

    const toggleModal = () => {
      setModal(!modal);
    };

    const [fields, setFields] = useState({});
    const [agreementsSchema, setSchema] = useState({});
    const [initialValues, setInitialValues] = useState({
        havingParticipantId: '',
        participantId: '',
        firstName: '',
        surname: '',
        mail: '',
        phoneNumber: '',
        birthDate: '',
        birthPlace: '',
        occupation: '',
        rightToProfessionNo: '',
        academicTitle: '',
        workPlace: '',
        isCDL: '',
        cdlCertificateNo: '',
        otherQualifications: [],
        city: '',
        placeType: 'Ulica',
        street: '',
        houseNo: '',
        localNo: '',
        postalCode: '',
        postCity: ''
    });

    useEffect(() => {
        fetch('https://prod-26.westeurope.logic.azure.com/workflows/a823e46a27754bbd97e2a8383a3a2eee/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=pjqU83WtOcMgNuv9J63gxJ2Ypahv5CcgKCEQz06mBLE', {
        method: 'POST', 
        headers : {
            'Content-Type' : 'application/json'
        },
        body: JSON.stringify({course_id: '#' + id})})
        .then(r => {
          if (r.status !== 200) {
            setModalText('Coś poszło nie tak. Spróbuj ponownie później.');
            toggleModal();
          }
          return r.json();
        })
        .then(response => {
            
            console.log(response);
            if (response.Status === 'false') {
              setModalText('Coś poszło nie tak. Spróbuj ponownie później.');
              toggleModal();
            } else {
              setFields(response);
              
              if (response.hasOwnProperty('Declarations')) {
                  let declarationsArr = {};
                  let agreementsValidationSchema = {};
                  response.Declarations.forEach(function(d, i) {
                      declarationsArr[`agreement_${d.ID}`] = false;
                      agreementsValidationSchema[`agreement_${d.ID}`] = (d.Required ? Yup.bool().oneOf([true]).required() : Yup.bool());
                  });
                  setInitialValues({...initialValues, ...declarationsArr});
                  setSchema(agreementsValidationSchema);
              }

              if (response.hasOwnProperty('Full_name_course')) {
                setEventName(response.Full_name_course);
              }
            }
        })
        .catch( error => {
          console.log('ERROR: ' + error);
          setModalText('Coś poszło nie tak. Spróbuj ponownie później.');
          toggleModal();
        });
    }, []);

    return (
        <div className="App">
          <header>
            <div className="App-header">
              <img src={cnolLogo} className="App-logo" alt="logo" />
              <img src={ftkLogo} className="App-logo" alt="logo" />
            </div>
            <div className="App-header-second">
              CENTRUM NAUKI O LAKTACJI IM. ANNY OSLISLO
            </div>
            <div className="App-header-third">{eventName}</div>
            <Modal modal={modal} content={modalText} toggleModal={toggleModal}/>
            {JSON.stringify(fields) !== JSON.stringify({}) ? <RegisterForm id={id} initialValues={initialValues} fields={fields} agreementsSchema={agreementsSchema} /> : null}
          </header>
        </div>
      );
}