import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';

import './css/coursesPage.css';
import cnolLogo from './cnol-logo.jpg';
import ftkLogo from './ftk-logo.jpg';
import Section from './section.js';
import Modal from './modal.js';

const nursesProps = {
  backgroundColor: "white",
  color: "#115e92",
  secondaryColor: "#588EB4",
  thirdColor: "#CEDFE9",
  name: "DLA PIELĘGNIAREK I POŁOŻNYCH"
};

const doctorsProps = {
  backgroundColor: "f6f6f6",
  color: "#3A7501",
  secondaryColor: "#749D4E",
  thirdColor: "#E1EAD9",
  name: "DLA LEKARZY"
};

const otherProps = {
  backgroundColor: "white",
  color: "#CC7901",
  secondaryColor: "#DBA14D",
  thirdColor: "#F8EBDA",
  name: "INNI SPECJALIŚCI"
};            

export default function CoursesPage() {
  const [coursesForNurses, setCoursesForNurses] = useState([]);
  const [coursesForDoctors, setCoursesForDoctors] = useState([]);
  const [otherCourses, setOtherCourses] = useState([]);

  const [modal, setModal] = useState(false);
  const [modalText, setModalText] = useState('');

  const toggleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    fetch('https://prod-37.westeurope.logic.azure.com/workflows/ea3d3530a1bf4e8cbb0c0a0c6fa0ccba/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=1l8T-otUuYuuJqXgEn_8BpC8tpfYYIiocP87mlGkbXc')
      .then(r=>r.json())
      .then(response => {
        
        console.log(response);

        setCoursesForNurses(response.filter(c => ['Pielęgniarka', 'Położna'].some(p => c.Profession.map(prof => prof.Value).includes(p))));
        setCoursesForDoctors(response.filter(c => c.Profession.map(p => p.Value).includes('Lekarz')));
        setOtherCourses(response.filter(c => c.Profession.map(prof => prof.Value).filter(p => !['Pielęgniarka', 'Położna', 'Lekarz'].includes(p)).length > 0));
      })
      .catch( error => {
        console.log('ERROR: ' + error);
        setModalText('Coś poszło nie tak. Spróbuj ponownie później.');
        toggleModal();
      })
  }, []);
  
    return (
        <div className="App">
          <header>
            <div className="App-header">
              <img src={cnolLogo} className="App-logo" alt="logo" />
              <img src={ftkLogo} className="App-logo" alt="logo" />
            </div>
            <div className="App-header-second">
              CENTRUM NAUKI O LAKTACJI IM. ANNY OSLISLO
            </div>
            <div className="App-header-third">
              Zapisy na wydarzenia
            </div>
    
            <Container fluid className="page">
              <Modal modal={modal} content={modalText} toggleModal={toggleModal}/>
              <Section {...nursesProps} courses={coursesForNurses} />
              <Section {...doctorsProps} courses={coursesForDoctors} />
              <Section {...otherProps} courses={otherCourses} />
            </Container>
          </header>
        </div>
      );
}