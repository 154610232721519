import { Field } from "formik";

export default function AgreementRow({data, name, errors, isSubmitted}) {
    let checkboxRequired = data.Required;
    
    return <>
        <label className={errors[name] && isSubmitted ? 'agreement-error' : null}>{data.Content + (checkboxRequired ? '*' : '')}
            <Field type="checkbox" name={name} content={data.Content} />
            <span></span>
        </label>
    </>;
}